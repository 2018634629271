@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;400;700&display=swap');

body {
    margin: 0;
    font-family: "Noto Sans JP", "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@tailwind base;
@tailwind components;
@tailwind utilities;


#webinar-article  p {
    line-height: 2rem;
    margin-top: 8px;
}


.mosaic{
    -ms-filter: blur(6px);
    filter: blur(6px);
}